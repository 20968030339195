const _ = require('lodash');

const _STORAGE_KEY = '_guru';
let _token = null;
let _token_retrieved = false;

function getToken() {
  if (!_token_retrieved) {
    _token = localStorage.getItem(_STORAGE_KEY);
    _token_retrieved = true;
  }
  return _.clone(_token);
}

function setToken(token) {
  _token = _.clone(token);
  _token_retrieved = true;
  localStorage.setItem(_STORAGE_KEY, _token);
}

function removeToken() {
  _token = null;
  _token_retrieved = true;
  localStorage.removeItem(_STORAGE_KEY);
}

module.exports = {
  getToken: getToken,
  setToken: setToken,
  removeToken: removeToken
}

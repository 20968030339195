let _working = false;

function getIframeElt(containerId) {
  let contElt = document.getElementById(containerId);
  if (contElt === null || contElt === undefined) {
    return null;
  }
  let iframes = contElt.getElementsByTagName('iframe');
  if (iframes.length === 0) {
    return null;
  }
  return iframes[0];
}

function isRendered(containerId) {
  return getIframeElt(containerId) !== null;
}

function render(containerId, callback) {
  return new Promise((res) => {
    let f = function() {
      setTimeout(() => {
        if (window.grecaptcha === undefined) {
          f(); 
        } else {
          window.grecaptcha.render(containerId, {
            sitekey: '6LfTXW4hAAAAAMZ0m0PCxxVh2eabA84rceVXY6qW',
            callback: callback
          });
          let iframe = getIframeElt(containerId);
          iframe.onload = (() => {
            res(true);
          });
        }
      }, 100)
    }
    f();
  })
}

function reset() {
  return new Promise((res) => {
    let f = function() {
      setTimeout(() => {
        if (window.grecaptcha === undefined) {
          f();
        } else {
          window.grecaptcha.reset();
          res(true);
        }
      }, 100)
    }
    f();
  });
}

function resetOrRender(containerId, callback) {
    return new Promise((res) => {
      let f = function() {
        setTimeout(() => {
          if (_working) {
            f();
          } else {
            _working = true;
            if (isRendered(containerId)) {
              reset().then(() => {
                _working = false;
                res(true);
              });
            } else {
              render(containerId, callback).then(() => {
                _working = false;
                res(true);
              })
            }
          }
        }, 100);
      }
      f();
    });
}

module.exports = {
  resetOrRender: resetOrRender,
}

import { createApp } from 'vue'
import * as VueRouter from 'vue-router'
import axios from 'axios'

import authService from './services/authService.js'
import captchaService from './services/captchaService.js'
import contentService from './services/contentService.js'

import HomeContent from './components/contents/HomeContent.vue'

import App from './App.vue'

var app = createApp(App)

let conf = {
  apiUrl: process.env.VUE_APP_API_URL || 'http://localhost:3000',
  authHeader: process.env.VUE_APP_AUTH_HEADER || 'X-Guru-Meditation',
}

// app conf
app.config.globalProperties.conf = conf;

// authService
app.config.globalProperties.authService = authService;
// captcha service
app.config.globalProperties.captchaService = captchaService;
window.captchaLoaded = captchaService.captchaLoaded

// axios
app.config.globalProperties.$http = axios
axios.interceptors.request.use(request => {
  const token = authService.getToken()
  const isApiUrl = request.url.startsWith(conf.apiUrl);

  if (isApiUrl && token !== null && token !== undefined && token !== '') {
    request.headers.common[conf.authHeader] = token
  }

  return request;
});
axios.interceptors.response.use(resp => {
  return resp;
}, error => {
  if (error.response.status === 401) {
    authService.removeToken()
  }
  return Promise.reject(error);
})

// content
app.config.globalProperties.contentService = contentService
contentService.init(axios, conf.apiUrl)

// router
const routes = [
  { path: '/', component: HomeContent },
]
const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes: routes, 
})
app.use(router);

// mount app
app.mount('#app')

let _http = null;
let _apiUrl = null;

function init(http, apiUrl) {
    _http = http;
    _apiUrl = apiUrl;
}

function retrievePrivateContent(contentId) {
    return _http
        .get(`${_apiUrl}/api/content/${contentId}`)
        .then(res => res.data)
}

function retrievePrivateAsset(assetPath) {
    return _http
        .get(`${_apiUrl}/private/${assetPath}`, {responseType:"blob" })
        .then(res => {
            let reader = new window.FileReader();
            reader.readAsDataURL(res.data);
            return new Promise(resolve => {
                reader.onload = function() {
                    resolve(reader.result)
                }
            })
        })
}

module.exports = {
    init: init,
    retrievePrivateContent: retrievePrivateContent,
    retrievePrivateAsset: retrievePrivateAsset
}
<template>
  <div class="topbar-container">
    <div class="topbar-content">
      <div class="logo" @click="gotoHome()">ExLibris Studios</div>
      <div>
        <ul class="uk-iconnav ext-links">
          <li v-if="itchioUrl">
            <a :href="itchioUrl"><i class="fa-brands fa-itch-io"></i> itch.io</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="main-container">
    <div class="side-menu-container">
      <img src="imgs/mage.png" alt="mage">
    </div>
    <div class="content-container">
      <EnterTheMatrix v-if="!checked" @checked="onChecked"></EnterTheMatrix>
      <div v-if="checked" class="content-view">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import EnterTheMatrix from './components/EnterTheMatrix.vue'

export default {
  name: 'App',
  components: {
    EnterTheMatrix
  },
  data() {
    return {
      checked: false,
      itchioUrl: null
    }
  },
  methods: {
    onChecked() {
      this.checked = true;
      this.contentService.retrievePrivateContent('dbc9ebbe-3767-4815-a26c-28a4e0540e41')
          .then(content => {
            this.itchioUrl = content.content
          })
    },
    gotoHome() {
      this.$router.push('/')
    }
  }
}
</script>
<style>
.topbar-container {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.topbar-content {
  display: flex;
  background: black;
  justify-content: space-between;
  width: 790px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}
.logo {
  font-weight: bold;
  font-size: 0.8em;
  cursor: pointer;
}
.main-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.content-container {
  width: 559px;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}
.side-menu-container {
  width: 249px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}
.ext-links {
  margin-top: 3px;
  font-size: 0.9em;
}

.ext-links a {
  color: white;
}

.ext-links a:hover {
  color: #3399ff;
}
</style>

<template>
  <div class="home-content">
    <p v-if="loading"></p>
    <p v-if="error">ERROR !!</p>
    <div v-if="title && contentText" class="content-frame">
      <div class="content-title">
        <div class="content-title-left"></div>
        <div class="content-title-text-bg">
          <div class="content-title-text">{{title}}</div>
        </div>
        <div class="content-title-right"></div>
      </div>
      <div class="content-text">
        <p>
          {{contentText}}
        </p>
        <p>&nbsp;</p>
        <p v-if="imgSrc" style="text-align: center; margin: 0">
          <img :src="imgSrc" alt="entry" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeContent',
    data() {
      return {
        loading: true,
        error: false,
        title: null,
        contentText: null,
        imgSrc: null,
      }
    },
    methods: {
      retrieveHomeContent() {
        this.loading = true
        Promise.all([
            this.contentService.retrievePrivateContent('07144f09-175d-4df1-96bd-d9fdb85228e9'),
            this.contentService.retrievePrivateAsset('/imgs/welcome.png')
        ]).then(([content, img]) => {
          this.loading = false
          this.error = false
          this.title = content.title
          this.contentText = content.content
          this.imgSrc = img
        }).catch(err => {
            console.error(err)
            this.loading = false
            this.error = true
        })
      }
    },
    mounted() {
      this.retrieveHomeContent()
    }
  }
</script>

<style scoped>
.home-content {
  margin: 5px;
}

.content-frame {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.content-title {
  display: flex;
}

.content-title-left {
  background: url("../../../public/imgs/title-l.png") no-repeat;
  width: 7px;
  height: 18px;
}

.content-title-right {
  background: url("../../../public/imgs/title-r.png") no-repeat;
  width: 7px;
  height: 18px;
}

.content-title-text-bg {
  flex-grow: 1;
  line-height: 1;
  background: url("../../../public/imgs/title-bg.png") repeat-x;
}

.content-title-text {
  font-size: 0.8em;
  font-weight: bold;
  margin-left: 7px;
  padding-left: 3px;
  padding-right: 3px;
  line-height: 1;
  display: inline-block;
  background: white;
}

.content-text {
  padding: 5px;
  text-align: center;
}
</style>

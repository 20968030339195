<template>
  <div class="entry-comp-container">
    <div v-if="loading" class="uk-animation-fade loader">
      <div uk-spinner="ratio: 3"></div>
    </div>

    <div :class="{hidden: loading}">
      <p class="uk-text-justify">
        This website features adult content and nudity and therefore is restricted to persons over 18 years of age.
      </p>
      <p class="uk-text-justify">
        If you are not permitted to view such material please leave this site now.
      </p>
      <p class="uk-text-justify">
        If you feel uncomfortable with nudity or sexually explicit content you should leave too,
        as we don't intend to offend you.</p>
      <div class="uk-flex uk-flex-center">
        <div :class="{hidden: !captcha}" id="g-captcha-container" class="uk-animation-fade"></div>
        <button class="uk-button uk-button-primary" v-if="!captcha" @click="enterClicked()">
          Enter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require('lodash');

export default {
  name: 'EnterTheMatrix',
  emits: ['checked'],
  data() {
    return {
      loading: true,
      captcha: false,
    }
  },
  methods: {
    entry: function(resp) {
      this.loading = true;
      this.captcha = false;
      this.$http
        .post(`${this.conf.apiUrl}/api/entry`, {gToken: resp})
        .then(resp => {
          this.loading = false
          let checkSuccess = resp.data.success && !_.isEmpty(resp.data.token)
          if (checkSuccess) {
            this.authService.setToken(resp.data.token);
          } else {
            this.captcha = true;
            this.authService.removeToken();
            this.captchaService.resetOrRender('g-captcha-container', this.entry)
          }
        })
        .catch(() => {
          this.loading = false
          this.captcha = true;
          this.authService.removeToken()
          this.captchaService.resetOrRender('g-captcha-container', this.entry)
        })
    },
    check: function() {
      if (!this.captcha) {
        this.loading = true;
      }
      this.$http
        .get(`${this.conf.apiUrl}/api/check`)
        .then(resp => {
          // check if access was already granted
          return resp.data.success;
        })
        .catch(() => {
          return false;
        }).then(captchaOk => {
          // render captcha if needed
          if (captchaOk) {
            return false;
          } else {
            this.authService.removeToken();
            return this.captchaService.resetOrRender('g-captcha-container', this.entry);
          }
        }).then(captchaRendered => {
          this.loading = false;
          this.captcha = captchaRendered;
        });
    },
    enterClicked() {
      this.$emit('checked');
    }
  },
  mounted () {
    this.check();
  }
}
</script>

<style scoped>
.hidden {
  display: none;
}

.loader {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.entry-comp-container {
  margin: 5px;
}

</style>
